/* global $ */
/* global window */
/* global basket */
/* global document */

/**
 * CustomEvent polyfill for IE
 */
(function () {
  if (typeof window.CustomEvent === 'function') return false
  function CustomEvent (event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined }
    var evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }
  CustomEvent.prototype = window.Event.prototype
  window.CustomEvent = CustomEvent
  return true
})()

// Create an event to track harvest errors, and tell the React templates about it
window.harvestErrorEvent = new CustomEvent('harvestError', {
  bubbles: true,
  cancelable: true
})

var getQueryParameters = function () {
  // Some code I stole that handled query paramaters
  // This will go once we refactor harvest
  var match
  var search = /([^&=]+)=?([^&]*)/g
  var decode = function (string) {
    return decodeURIComponent(string.replace(/\+/g, ' '))
  }

  var urlParams = {}
  while (match = search.exec(window.location.search.substring(1))) { // eslint-disable-line no-cond-assign
    urlParams[decode(match[1])] = decode(match[2])
  }
  return urlParams
}

/**
* harvest
* Used to communicate between the web layer and harvest via the wall
* @param {string} options - The method to be used on the request.
* @param {string} options.id - The id of the basket you are requesting. Required for get and put requests.
* @param {object} options.requestData - An object of all the request data to pass to harvest
* @returns {object} harvest promise
*/
var harvest = function (options) {
  // All the endpoints that harvest will use
  var endpoints = {
    get: '/basketFetch/' + options.id,
    put: '/basketUpdate/' + options.id,
    post: '/basketCreate'
  }

  // If we don't have a method, lets pretend it's get
  options.method = options.method ? options.method : 'get'

  // Make sure we have the correct params
  if (options.method !== 'post' && options.id === undefined) {
    throw new Error('No id found, id is required for ' + options.method + '.')
  }

  return $.ajax({
    url: '//' + document.location.host + endpoints[options.method] + (window.queryParams.referrer ? '?referrer=' + window.queryParams.referrer: ''),
    type: options.method,
    data: options.requestData,
    dataType: 'json',
    xhrFields: {
      withCredentials: true
    }
  })
    .done(function (data) {
      return data
    })
    .fail(function (err) {
      return err
    })
}

/**
* updateBasket
* Used to start up harvest and return the basket to the template
* @param {object} options - The request data to pass to harvest
* @return {object} harvest promise
*/
window.updateBasket = function (options) {
  var defer = $.Deferred() // eslint-disable-line new-cap

  if (options.method !== 'post' && !options.basketId) {
    throw new Error('No id found, id is required to update basket.')
  }

  var harvestObject = {
    method: options.method,
    requestData: {
      tag: window.endpoint
    }
  }

  switch (options.method.toLowerCase()) {
    case 'get':
      harvestObject.id = options.basketId
      break
    case 'post':
      harvestObject.requestData.data = options.data
      break
    case 'put':
      harvestObject.id = options.basketId
      harvestObject.requestData.version = options.version
      if (options.data) {
        harvestObject.requestData.data = options.data
      } else {
        harvestObject.requestData.newVersion = true
      }
      break
  }

  return $.when(harvest(harvestObject)).then(function (data) {
    // Things went really well, so thats good.
    return defer.resolve(data)
  }, function (data) {
    // Things didn't go so well, we have a problem.
    document.dispatchEvent(window.harvestErrorEvent)
    return defer.reject(data)
  })
}

// Only do this when the DOM is ready. That way we know the GA plugin has initialized and any error in this function won't track and cause a self referral.
$(document).ready(function () {
  window.queryParams = getQueryParameters()
  var harvestObject = {}

  // Landing and Preference Centre don't do anything with the basket
  if (window.endpoint !== 'landing' && window.endpoint !== 'preferencecentre') {
    // Create a basket when we don't have one
    if (!window.queryParams.basketId) {
      harvestObject = {
        data: window.queryParams,
        method: 'post'
      }
    } else {
      harvestObject = {
        basketId: window.queryParams.basketId,
        method: 'put',
        newVersion: true,
        tag: window.endpoint,
        version: window.queryParams.versionId
      }
    }
  }

  window.harvestHasAnError = false
  if (Object.keys(harvestObject).length > 1) {
    $.when(window.updateBasket(harvestObject)).then(
      function (data) {
        if (data && data.harvest && data.harvest.baskets) {
          window.basket = data.harvest.baskets
        }
        if (!window.basket || window.basket.error) {
          // Harvest is not happy!
          window.harvestHasAnError = true
          document.dispatchEvent(window.harvestErrorEvent)
        }
      },
      function () {
        // Things didn't go so well, we have a problem.
        window.harvestHasAnError = true
        document.dispatchEvent(window.harvestErrorEvent)
      }
    )
  }
})

// Toggle hide class on a specific target from data attribute.
// Used by the Staff bar
$('.js-toggle-class').on('click', function () {
  $($(this).data('target')).toggleClass('hide')
})

window.loadZopim = function (callback) {
  // this function might seem strange, but unfortunately during a setInterval the window.$zopim variable never updates, so we need a getter for it
  var isLiveChatLoaded = function isLiveChatLoaded() {
    return window.$zopim.livechat;
  };

  window.chatReadyCallback = function chatReadyCallback() {
    // the setInterval here is really nasty, but unfortunately we have no way of knowing when zopim is initialised (no callbacks, boo)
    var checkForInstantiatedZopim = function() {
      if (isLiveChatLoaded()) {
        clearInterval(window.zopimCheck);
        callback(window.$zopim.livechat);
      }
    };
    window.zopimCheck = setInterval(checkForInstantiatedZopim, 50);
  };

  var userAgent = navigator.userAgent.toLowerCase();
  var platform = navigator.platform.toLowerCase();
  var platformName = userAgent.match( /ip(?:ad|od|hone)/ ) ? 'ios' : (userAgent.match( /( ?:webos|android ) /) || platform.match( /mac|win|linux/ ) || ['other'] )[0];
  var isMobile = /ios|android|webos/.test(platformName);

  if (!isMobile) {
    window.$zopim||(function(d,s){
      var z=window.$zopim=function(c){
        z._.push(c)
      },
      $=z.s=d.createElement(s),
      e=d.getElementsByTagName(s)[0];
      z.set=function(o){
        z.set._.push(o)
      };
      z._=[];
      z.set._=[];
      $.async=!0;
      $.setAttribute('charset','utf-8');
      $.src='//v2.zopim.com/?2nxkcWMfb9CJiucrO6KDeOL4a3pOiaRf';
      z.t=+new Date;
      $.type='text/javascript';
      $.setAttribute('onload', 'window.chatReadyCallback()');
      e.parentNode.insertBefore($,e)
    })(document,'script');
  }
};
